import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/markup.module.scss';
import { detectNetworkByAddress } from '@aml/validation';
import { useNavigate } from 'react-justanother-router';
import { RouterName } from '../../router';
import { FormContainer } from '@component/FormContainer';
import cx from 'classnames';
import { WrapperFinderContent } from './WrapperFinderContent';
import { RegistrationComponent } from './RegistrationComponent';
import { PermissionWrapper } from './PermissionWrapper';
import { useMeQuery } from '@apolloGenerated';
import { Informer, Button } from '@rubin-dev/goblin';
import { PROJECT_TYPE } from '@shared/libs';

export type PropsType = {
  checkCount?: number;
  query: {
    query: string;
  };
  onCheck: () => void;
  onClick: () => void;
};

export const FinderTab: FC<PropsType> = ({ query, onCheck, checkCount, onClick }) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string>(query.query || '');
  const [network, setNetwork] = useState<string | undefined>(
    address ? detectNetworkByAddress(address) : undefined,
  );

  const { data: user, loading } = useMeQuery();

  const { navigate } = useNavigate();

  useEffect(() => {
    if (!query.query) {
      return;
    }

    setAddress(query.query.trim());
    setNetwork(detectNetworkByAddress(query.query));
  }, [query.query]);

  const onSubmit = useCallback(
    (val: string) => {
      navigate(RouterName.Markup, {}, { query: val });
    },
    [query.query],
  );

  const hasData = network && address;

  return (
    <div className={styles['markup__wrapper']}>
      <FormContainer
        initialValues={{ query: query.query }}
        formOnSubmit={(value) => onSubmit(value.query)}
      />
      {!loading && (
        <div
          className={cx(
            styles['markup__card'],
            styles['markup__card_column'],
            !user && styles['markup__need-reg'],
            !(network || address) && styles['markup__content-center'],
          )}
        >
          {query.query && !detectNetworkByAddress(query.query) ? (
            <div className={styles['markup__not-found']}>
              <Informer
                type="empty"
                title={''}
                subtitle={
                  PROJECT_TYPE === 'hermespro'
                    ? t('titles.noData')
                    : t('titles.markupNotFound')
                }
              />
              {PROJECT_TYPE !== 'hermespro' && (
                <Button to={RouterName.MarkingCreate}>{t('marking.add')}</Button>
              )}
            </div>
          ) : (
            <>
              <WrapperFinderContent
                contentSlot={
                  <>
                    {!user ? (
                      <RegistrationComponent />
                    ) : (
                      hasData && (
                        <PermissionWrapper
                          network={network}
                          address={address}
                          subscribeCallback={onCheck}
                          checkCount={checkCount}
                          onClick={onClick}
                        />
                      )
                    )}
                  </>
                }
                hasContent={!(network || address)}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
